.user {
    cursor: pointer;
    :hover {
        background-color: orange;
    }
}

.panel {
    width: 100px;
    border: 1px solid black;
    border-radius: 5px;
}