.active {
    color: green;
}

.inactive {
    color: yellow;
}

.disconnected {
    color: red;
}