ul {
    width: 150px;
}

li {
    list-style-type: none; 
    display: grid;
}

.delete {
    color: red;
    /* display: inline-block; */
    grid-column: 2;
}

.sessionName {
    color: purple;
    background-color: cornflowerblue;
    /* display: inline-block; */
    grid-column: 1;
}