.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.45);
    top: 0;
}

.opaque {
    background-color: rgba(255, 255, 255, 1);
}

.loadingWrapper {
    position: relative;
    display: inline-block;
}

.spinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(127, 127, 127, 0.5);
    height: 100%;
}

.spinner {
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-name: spinner;
    width: 50%;
    max-width: 100px;
    transform-origin: 49% 55%;
}

@keyframes spinner {
    0% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(360deg)
    }
}