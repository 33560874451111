.indicator {
  /* border:1px solid white;
  border-radius: 5px;
  background: rgba(200,200,200); */
  // position: absolute;
  // bottom: 0px;
  margin: 1px;
}

.editorInfoBar {
  position: absolute;
  display: flex;
  bottom: 0;
  align-items: center;
}

.editorPane {
  position: relative;
}

.container {
  height: 100%;
  background-color: midnightblue;
}

.secondaryPane {
  width: 100%;
  margin: 10px;


}

.chatResult {
  width: 100%;
  padding: 20px;
  background-color: orange;

  .questionBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .dropdown {
      margin-left: 10px;
      border: 1px solid black;
      border-radius: 4px;
      color: black;
    }

    .aiButton {
      border: 1px solid black;
      border-radius: 4px;
      color: black;
      font-weight: 800;
    }
  }



  .loading {
    height: 100px;
    width: 100px;
  }

  h4 {
    margin: 10px;
  }

  p {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

.interviewerPanel {
  display: flex;

  div {
    margin-right: 10px;
  }
}